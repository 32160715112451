<template>
    <div class="upsell-modal-container">
        <div class="modal-header">
            <div @click="closeModal" class="cursor-pointer">
                <close-icon class="!fill-slate-700" />
            </div>
        </div>

        <div class="modal-body">
            <div class="left-side">
                <div class="flex gap-3 items-center">
                    <!-- TODO (Joe) Update SVG Icon -->
                    <div class="pb-logo-container">
                        <!-- <pb-lock-logo /> -->
                        <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_101_811)">
                                <path
                                    d="M5.9513 17.935H5.98665L3.42097 15.9512H5.92214H5.99819H8.61083L5.99819 17.9714L5.9513 17.935ZM6.0335 14.1172H5.92214H1.82558H1.73651C1.43578 13.5752 1.18845 12.9133 1.07251 12.1336H5.92214H5.99819H10.9593C10.8435 12.9133 10.5962 13.5752 10.2953 14.1172H6.0335ZM6.0335 10.2996H5.92214H1V8.31589H5.92214H5.99819H11.0317V10.2996H6.0335Z"
                                    fill="#FCB223"
                                />
                            </g>
                            <g filter="url(#filter1_d_101_811)">
                                <path
                                    d="M5.98336 1C6.9814 1 7.88832 1.44226 8.54513 2.15458C9.2022 2.86714 9.61 3.85046 9.61 4.93277V6.86696L8.34806 6.62041V4.93277C8.34806 4.22842 8.08173 3.58721 7.65294 3.12219C7.22414 2.65715 6.63307 2.36832 5.98336 2.36832C5.33387 2.36832 4.74261 2.65715 4.31382 3.12219C3.88501 3.58721 3.61869 4.22842 3.61869 4.93277V6.62067L2.35696 6.86696V4.93277C2.35696 3.85046 2.76477 2.86714 3.4216 2.15458C4.07865 1.44226 4.98535 1 5.98336 1Z"
                                    fill="#FCB223"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_101_811"
                                    x="0.5"
                                    y="7.81589"
                                    width="11.0317"
                                    height="10.6555"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="0.25" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.160784 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_101_811" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_101_811" result="shape" />
                                </filter>
                                <filter
                                    id="filter1_d_101_811"
                                    x="1.85696"
                                    y="0.5"
                                    width="8.25302"
                                    height="6.86697"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="0.25" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.160784 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_101_811" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_101_811" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>

                    <div>
                        <h1>Upgrade to Privacy Bee Pro</h1>
                        <!-- <span class="upsell-paragraph">
                            Some really fancy and catching tagline talking about how Privacy Bee will totally keep you and your
                            data private.
                        </span> -->
                    </div>
                </div>

                <div class="pl-11">
                    <div class="divider"></div>
                </div>

                <div class="flex flex-col gap-6">
                    <div class="upsell-note" v-for="(note, index) in upsell_notes" :key="index">
                        <div class="w-8 h-8 flex items-center justify-center">
                            <checkmark-icon />
                        </div>
                        <p>
                            <span class="text-style-link">{{ note.title }}</span> {{ note.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="right-side">
                <!-- Error Messages -->
                <!-- TODO (Joe) These work, but need to show them only upon error via the API -->
                <!-- <div v-if="errors.length" class="error-messages mb-4">
                    <ul>
                        <li v-for="(error, index) in errors" :key="index" class="text-red-600 text-sm">
                            {{ error }}
                        </li>
                    </ul>
                </div> -->

                <div class="payment-container">
                    <div class="flex flex-col gap-4">
                        <div class="sub-duration-badge">
                            <label class="text-slate-500">Annual Subscription</label>
                        </div>

                        <div class="flex items-center gap-3">
                            <h1 class="text-[40px]">$197.00</h1>
                            <div class="per-year">per year</div>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="flex items-center justify-between gap-2 flex-col md:flex-row">
                        <prime-input-text
                            v-model="firstName"
                            placeholder="First Name"
                            :invalid="!useFormValidation('name', firstName) && firstName.length > 0"
                            fluid
                        />
                        <prime-input-text
                            v-model="lastName"
                            placeholder="Last Name"
                            :invalid="!useFormValidation('name', lastName) && lastName.length > 0"
                            fluid
                        />
                    </div>
                    <div class="flex items-center mt-4">
                        <PrimeInputMask
                            v-model="cardNumber"
                            mask="9999 9999 9999 9999"
                            placeholder="1234 5678 9012 3456"
                            fluid
                            :invalid="!useFormValidation('card', cardNumber) && cardNumber.length > 0"
                        />
                    </div>
                    <div class="flex items-center justify-start gap-2 my-4">
                        <PrimeInputMask
                            v-model="expiryDate"
                            mask="99/99"
                            placeholder="MM/YY"
                            :invalid="!useFormValidation('exp', expiryDate) && expiryDate.length > 0"
                            class="max-w-20"
                        />
                        <PrimeInputMask
                            v-model="cvv"
                            mask="999"
                            placeholder="CVV"
                            :invalid="!useFormValidation('cvv', cvv) && cvv.length > 0"
                            class="max-w-20"
                        />
                    </div>
                    <prime-button variant="primary" class="w-full" :disabled="!isFormValid" @click="submitForm">
                        Begin Protection
                    </prime-button>
                    <div class="flex flex-col space-y-1 mt-4">
                        <p class="text-xs text-slate-500">Includes one full year of Privacy Bee Pro services for one person.</p>
                        <p class="text-xs text-slate-500">Additional identities may incur additional costs.</p>
                    </div>
                </div>

                <div class="divider relative !mt-8">
                    <div class="flex justify-center absolute w-full top-[-10px]">
                        <span class="recommended-text">#1 Recommended by Privacy Professionals</span>
                    </div>
                </div>

                <div class="company-logos-container">
                    <img src="@/assets/images/upsell-companies/pc-mag.png" alt="PC Mag" />
                    <img src="@/assets/images/upsell-companies/vb.png" alt="VB" />
                    <img src="@/assets/images/upsell-companies/readers-digest.png" alt="Reader's Digest" />
                    <img src="@/assets/images/upsell-companies/ibt.png" alt="IBT" />
                    <img src="@/assets/images/upsell-companies/zd-net.png" alt="ZD Net" />
                    <img src="@/assets/images/upsell-companies/business-com.png" alt="Business.com" />
                    <img src="@/assets/images/upsell-companies/m-series.png" alt="M Series" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import CloseIcon from '@/assets/icons/close.svg';
import CheckmarkIcon from '@/assets/icons/checkmark.svg';
import PbLockLogo from '@/assets/branding/small-lock-logo.svg';
import PrimeInputMask from 'primevue/inputmask';
import PrimeInputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';

import { inject } from 'vue';

const dialogRef = inject('dialogRef');

// Reactive form fields
const firstName = ref('');
const lastName = ref('');
const cardNumber = ref('');
const expiryDate = ref('');
const cvv = ref('');

// Reactive errors array
const errors = ref([]);

// Upsell notes data
const upsell_notes = [
    {
        title: 'Delete your contact info',
        description: 'from hundreds of Data Brokers and People Search Sites',
    },
    {
        title: 'Scrub your publicly exposed info',
        description: 'from Google and other search engines',
    },
    {
        title: 'Zero-Trust browser extension',
        description: 'for surfing the web without being tracked',
    },
    {
        title: '24/7/365 monitoring',
        description: 'for proactive action against re-exposures or data breaches',
    },
    {
        title: 'Dozens',
        description: 'more privacy-focused features and tools',
    },
];

// Computed property to validate form
const isFormValid = computed(() => {
    if (!useFormValidation('name', firstName.value)) return false;
    if (!useFormValidation('name', lastName.value)) return false;
    if (!useFormValidation('card', cardNumber.value)) return false;
    if (!useFormValidation('exp', expiryDate.value)) return false;
    if (!useFormValidation('cvv', cvv.value)) return false;

    return true;
});

// Function to close the modal
function closeModal() {
    dialogRef.value.close();
}

// Function to handle form submission
function submitForm() {
    // Proceed with form submission logic
    // For example, send data to the server
    console.log('Form Submitted:', {
        firstName: firstName.value,
        lastName: lastName.value,
        cardNumber: cardNumber.value,
        expiryDate: expiryDate.value,
        cvv: cvv.value,
    });
    // Close the modal after submission
    closeModal();
}
</script>

<style lang="scss" scoped>
.upsell-modal-container {
    @apply bg-white-700 rounded-md max-w-[97vw];
    @apply lg:w-fit;
}

.modal-header {
    @apply h-12 flex justify-end items-center px-3;
}

.modal-body {
    // Edit scrollbar
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: theme('colors.slate-300');
        border-radius: 4px;
    }

    @apply grid-cols-1 px-6 pb-6 overflow-y-scroll max-h-[90vh];
    @apply xl:px-12 xl:pb-12 grid gap-8 xl:grid-cols-2;
}

// Body left-side
.pb-logo-container {
    @apply bg-slate-700 w-8 h-8 flex justify-center items-center rounded-full relative;
}

.upsell-note {
    @apply flex gap-4 items-center text-slate-700;

    p {
        color: theme('colors.slate-700') !important;
    }
}

.divider {
    @apply bg-slate-200 w-full h-[2px] my-6;
}

// .upsell-paragraph {
//     @apply text-slate-600 font-inter text-xs font-normal;
// }

.sub-duration-badge {
    @apply border-blue-300 bg-white-700 border px-3 rounded-full w-fit flex justify-center py-1;
}

// Body right-side
.payment-container {
    @apply bg-blue-200 p-8 rounded-lg;
}

.per-year {
    @apply text-slate-600 font-inter text-sm font-normal leading-5;
}

.recommended-text {
    @apply text-center text-slate-600 text-xs font-semibold leading-5 bg-white-700 px-3;
}

.company-logos-container {
    @apply grid grid-flow-col items-center justify-between;

    img {
        @apply max-w-9;
    }
}

.left-side {
    @apply max-w-[480px];
}

/* Error Messages Styling */
.error-messages {
    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
}
</style>
